// AddOptionsMenu.js
import React from 'react';
import { MdAddCircle } from "react-icons/md";
import { SiRundeck } from "react-icons/si";
import { useTranslation } from 'react-i18next';

const AddOptionsMenu = ({ isVisible, onRunTest, onAddSkis }) => {
  const { t } = useTranslation();

  if (!isVisible) return null;

  return (
    <div className="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2/3 space-y-3 cursor-pointer text-btntxt font-semibold md:static md:m-2 md:w-full animate-fade md:animate-fade-down animate-duration-300 md:animate-duration-200">
      <div onClick={onRunTest} className="bg-btn shadow rounded-3xl flex justify-between items-center p-4 hover:opacity-90">
        <p>{t('parallel_test')}</p>
        <SiRundeck />
      </div>
      <div onClick={onAddSkis} className="bg-btn shadow rounded-3xl flex justify-between items-center p-4 hover:opacity-90">
        <p>{t('add_ski_pair')}</p>
        <MdAddCircle />
      </div>
    </div>
  );
};

export default AddOptionsMenu;
