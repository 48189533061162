import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { addUserTournamentResults, updateSkiRanking } from '../../../firebase/firestoreFunctions';
import { calculateSkiRankings, mapRankingsToTournamentData } from '../../../helpers/helpers';
import ResultList from './ResultList/ResultList';
import Spinner from '../../../components/common/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import SaveTestInput from './SaveTestInput/SaveTestInput';




const TestSummaryPage = ({ skis, calculateRankings, goBack }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const rankings = calculateRankings();


    useEffect(() => {
        // Function to execute when the event is triggered
        const handleBeforeUnload = (e) => {
            const message = "Are you sure you want to leave? Any unsaved changes will be lost.";
            e.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
        };

        // Adding the event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Removing the event listener on cleanup
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const skiRankings = calculateSkiRankings(rankings, skis);


    const [additionalData, setAdditionalData] = useState({
        location: '',
        style: '',
        temperature: '',
        snowTemperature: '',
        humidity: '',
        snowType: '',
        comment: '',
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAdditionalData({ ...additionalData, [name]: value });
    };

    const handleSaveResults = async (e) => {
        e.preventDefault();
        if (!user) {
            console.log('User not found');
            return;
        }

        try {
            setLoading(true);

            // Store the returned testId from addUserTournamentResults
            const testId = await addUserTournamentResults(user.uid, mapRankingsToTournamentData(rankings), additionalData);

            // Pass the testId to updateAllSkiRankings
            await updateAllSkiRankings(user.uid, skiRankings, testId);
        } catch (error) {
            console.error('Error: ', error);
        } finally {
            setLoading(false);
            navigate('/results');
        }
    };

    if (loading) return <Spinner />

    return (
        <div className='p-4'>
            <h3 className='text-3xl mb-5 font-semibold text-dominant'>{t('summary')}</h3>
            <div className='space-y-5'>
                <div className=''>
                    <ResultList rankings={rankings} />
                </div>
                <div>
                    <form className='rounded-xl flex flex-col gap-3 md:grid md:grid-cols-2 text-black my-2' onSubmit={handleSaveResults}>

                        <SaveTestInput type="text" name="location" placeholder={t('location')} onChange={handleInputChange} value={additionalData.location} required />
                        <SaveTestInput type="text" name="style" placeholder={t('style')} onChange={handleInputChange} value={additionalData.style} required />
                        <SaveTestInput type="number" name="temperature" placeholder={t('temperature')} value={additionalData.temperature} onChange={handleInputChange} required />
                        <SaveTestInput type="number" name="snowTemperature" placeholder={t('snow_temperature')} value={additionalData.snowTemperature} onChange={handleInputChange} />
                        <SaveTestInput type="number" name="humidity" placeholder={t('humidity')} value={additionalData.humidity} onChange={handleInputChange} />
                        <SaveTestInput type="text" name="snowType" placeholder={t('snow_type')} value={additionalData.snowType} onChange={handleInputChange} />
                        <div className='col-span-2'>
                            <SaveTestInput type="text" name="comment" placeholder={t('comment')} value={additionalData.comment} onChange={handleInputChange} />
                        </div>
                        <div className='col-span-1 space-x-2 font-semibold'>
                            <button className='px-5 py-3 bg-btn text-btntxt my-2 rounded-3xl w-fit'>{t('save')}</button>

                            <button className='px-5 py-3 bg-sbtn text-text rounded-3xl w-fit' onClick={goBack}>{t('back')}</button>

                        </div>


                    </form>

                </div>
            </div>


        </div>
    );
};

const updateAllSkiRankings = async (userId, skiRankings, testId) => {
    for (const ski of skiRankings) {
        await updateSkiRanking(userId, ski.id, testId);
    }
};

export default TestSummaryPage;

