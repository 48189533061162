// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDNA3e86hLGxpTLi7nKc3NhNqU7XXl6N0A",
  authDomain: "ski-test-10e6e.firebaseapp.com",
  projectId: "ski-test-10e6e",
  storageBucket: "ski-test-10e6e.appspot.com",
  messagingSenderId: "543267941297",
  appId: "1:543267941297:web:b869d8cfbe3752bbe6ab90",
  measurementId: "G-8HR8MY489T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
