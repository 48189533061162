// Spinner.js
import React from 'react';
import './Spinner.css';

const Spinner = () => (
  <div className="w-full h-96 flex flex-col items-center justify-center ">
    <div className="ski bg-gradient-to-r from-btn to-text h-1 w-6" id="ski1"></div>
    <div className="ski bg-gradient-to-r from-btn to-text h-1 w-6" id="ski2"></div>
    <div className="ski bg-gradient-to-r from-btn to-text h-1 w-6" id="ski3"></div>
    <div className="ski bg-gradient-to-r from-btn to-text h-1 w-6" id="ski4"></div>
  </div>
);

export default Spinner;
