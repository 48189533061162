import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdSearch } from "react-icons/md";

const ResultsSearch = ({ onSearchChange }) => {
    const { t } = useTranslation();

    return (
        <div className='flex flex-col w-2/3'>
            <label className='text-sm font-semibold mb-2' htmlFor="search-input">
                {t('search')}
            </label>
            <div className='flex items-center bg-container ring-1 ring-sbtn rounded-xl overflow-hidden focus-within:border-none focus-within:ring-1 focus-within:ring-btn'>
                <MdSearch className='text-lg text-text mx-2' />
                <input
                    id="search-input"
                    type="text"
                    placeholder={t('snr_style_location_grind')}
                    onChange={e => onSearchChange(e.target.value)}
                    className="w-full bg-container outline-none py-2 pr-2"
                />
            </div>
        </div>
    );
};

export default ResultsSearch;
