// Navigation.js
import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SlOptions } from "react-icons/sl";
import { FaHouse, FaList, FaChartSimple, FaCirclePlus } from "react-icons/fa6";
import { MdStorage, MdAdd } from "react-icons/md";
import useOutsideClick from '../../../hooks/useOutsideClick';

import AddOptionsMenu from './AddOptionsMenu';
import SubNavigation from './SubNavigation';
import Overlay from '../../common/Overlay/Overlay';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { t } = useTranslation();

  const [showAddOptions, setShowAddOptions] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const bottomNavRef = useRef(null);

  useOutsideClick(bottomNavRef, () => {
    if (showAddOptions) setShowAddOptions(false);
    if (isNavVisible) setIsNavVisible(false);
  });

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
    if (!isNavVisible) setShowAddOptions(false);
  };

  const handleAddClick = () => {
    setShowAddOptions(!showAddOptions);
    if (!showAddOptions) setIsNavVisible(false);
  };

  const handleAddSkis = () => {
    setShowAddOptions(false);
    navigate('/add-skis');
  };

  const handleRunTest = () => {
    setShowAddOptions(false);
    navigate('/skis');
  };

  const handleNavItemClick = (path) => {
    navigate(path);
    setIsNavVisible(false);
    setShowAddOptions(false);
  };

  const isActive = (path) =>
    currentPath === path ? 'font-semibold border-btn border-t-2 md:border-t-0 md:bg-sbtn md:rounded-xl md:flex md:justify-between md:items-center md:w-full' : '';

  return (
    <>
      <Overlay isVisible={isNavVisible || showAddOptions} />
      <div ref={bottomNavRef} className={`bg-background md:text-sm fixed w-full bottom-0 grid grid-cols-5 md:flex md:flex-col items-center z-20 transition-all duration-200 ease-in-out ${showAddOptions || isNavVisible ? 'md:w-52' : 'md:w-28'} md:justify-center md:flex-col md:h-screen md:p-4`}>
        <button onClick={() => handleNavItemClick('/')} className={`flex flex-col items-center p-4 focus:outline-none md:w-auto hover:font-semibold ${isActive('/')} ${showAddOptions && 'md:animate-fade-up md:animate-duration-500'} ${isNavVisible && 'md:animate-fade-up md:animate-duration-500'}`}>
          <FaHouse size={20} />
          <span className="hidden md:block">{t('home')}</span>
        </button>
        <button onClick={() => handleNavItemClick('/skis')} className={`flex flex-col items-center p-4 focus:outline-none md:w-auto hover:font-semibold ${isActive('/skis')} ${showAddOptions && 'md:animate-fade-up md:animate-duration-300'} ${isNavVisible && 'md:animate-fade-up md:animate-duration-[400ms]'}`}>
          <MdStorage size={22} />
          <span className="hidden md:block">{t('skipark')}</span>
        </button>
        <button className={`flex flex-col items-center md:p-4 focus:outline-none md:w-auto ${isActive('/add')} ${showAddOptions && 'md:animate-fade-up md:animate-duration-200'} ${isNavVisible && 'md:animate-fade-up md:animate-duration-300'}`} onClick={handleAddClick}>
          <div className="bg-btn text-btntxt rounded-full p-2 focus:outline-none">
            <MdAdd size={20} />
          </div>
        </button>

        <AddOptionsMenu isVisible={showAddOptions} onRunTest={handleRunTest} onAddSkis={handleAddSkis} />
        <button onClick={() => handleNavItemClick('/results')} className={`flex flex-col items-center p-4 focus:outline-none md:w-auto hover:font-semibold ${isActive('/results')} ${showAddOptions && 'md:animate-fade-down md:animate-duration-[250ms]'} ${isNavVisible && 'md:animate-fade-up md:animate-duration-300'}`}>
          <FaChartSimple size={20} />
          <span className="hidden md:block">{t('results')}</span>
        </button>
        <button onClick={toggleNav} className={`flex flex-col items-center p-4 focus:outline-none md:w-auto hover:font-semibold ${showAddOptions && 'md:animate-fade-down md:animate-duration-500'} ${isNavVisible && 'md:animate-fade-up md:animate-duration-200'}`}>
          <SlOptions size={20} />
          <span className="hidden md:block">{t('more')}</span>
        </button>
        <SubNavigation isVisible={isNavVisible} onClose={() => setIsNavVisible(false)} />
      </div>
    </>
  );
};

export default Navigation;
