import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSkis } from '../../hooks/useSkis';
import SkiItem from './SkiItem/SkiItem';
import Spinner from '../../components/common/Spinner';
import { SiRundeck } from "react-icons/si";
import SkiFilter from './SkiFilter/SkiFilter';
import SkiSort from './SkiSort/SkiSort';
import { MdAdd  } from "react-icons/md";



const SkisPage = () => {
  const { skis, loading, error, updateSkisList, deleteSki, updateSki } = useSkis();
  const [selectedSkis, setSelectedSkis] = useState({});
  const [selectedSkisForTournament, setSelectedSkisForTournament] = useState([]);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('serialNumber'); // State for sort value
  const [expandedSkiId, setExpandedSkiId] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedSkisForTournament(getSelectedSkis());
  }, [selectedSkis, skis]);

  const toggleDetails = (skiId) => {
    setExpandedSkiId(expandedSkiId === skiId ? null : skiId);
  };

  const getSelectedSkis = () =>
    Object.entries(selectedSkis)
      .filter(([_, isSelected]) => isSelected)
      .map(([id]) => skis.find(ski => ski.id === id));

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleSortChange = (sortField) => {
    setSort(sortField); // Update sort state
    const sortedSkis = [...skis].sort((a, b) =>
      a[sortField] < b[sortField] ? -1 : a[sortField] > b[sortField] ? 1 : 0
    );
    updateSkisList(sortedSkis);
  };

  const handleArchive = async (skiId) => {
    const skiToUpdate = skis.find(ski => ski.id === skiId);
    if (skiToUpdate) {
      await updateSki(skiId, { archived: true });
    }
  };

  const handleUnarchive = async (skiId) => {
    const skiToUpdate = skis.find(ski => ski.id === skiId);
    if (skiToUpdate) {
      await updateSki(skiId, { archived: false });
    }
  };

  const handleAddSki = () => {
    navigate('/add-skis'); // Redirect to the add skis page
  };

  const handleEdit = (ski) => {
    navigate('/add-skis', { state: { ski, isEdit: true } });
  };

  const handleDelete = async (skiId) => {
    if (window.confirm("Are you sure you want to delete this ski?")) {
      if (window.confirm("This will also remove the ski from results? You can't undo this")) {
        await deleteSki(skiId);
      }
    }
  };

  const handleCheckboxChange = (skiId) => {
    setSelectedSkis((prev) => ({ ...prev, [skiId]: !prev[skiId] }));
  };

  const handleStartTournament = () => {
    if (selectedSkisForTournament.length > 1) {
      navigate('/testing', { state: { selectedSkis: selectedSkisForTournament } });
    } else {
      alert("Please select more ski pairs");
    }
  };

  if (loading) return <Spinner />;
  if (error) return <div className='m-2'>Error: {error.message}</div>;

  const filteredSkis = [...new Set(skis
    .filter(ski => {
      if (filter === 'all_with_archive') return true; // Show all skis (including archived)
      if (filter === 'archived') return ski.archived; // Only show archived skis
      if (!filter) return !ski.archived; // Show non-archived skis when "All" is selected
      return ski.style.toLowerCase() === filter && !ski.archived; // Filter by style and exclude archived
    })
    .map(ski => ski.id))].map(id => skis.find(ski => ski.id === id));

  // Sort filtered skis
  const sortedAndFilteredSkis = filteredSkis.sort((a, b) =>
    a[sort] < b[sort] ? -1 : a[sort] > b[sort] ? 1 : 0
  );

  return (
    <HelmetProvider>
      <Helmet>
        <title>mySkiPark: Skis</title>
      </Helmet>
      <div className="skis-page p-4">

        <div className="flex flex-col md:flex-row md:justify-between space-y-2">
          {/* Start Test button on top for small screens, on the left for medium screens */}
          <div className="flex flex-col items-center justify-end">
            <h3 className="text-sm font-semibold mb-1">
              {selectedSkisForTournament.length} {t('skipairs_selected')}
            </h3>
            <button
              onClick={handleStartTournament}
              disabled={selectedSkisForTournament.length < 2}
              className={`flex items-center justify-center bg-btn text-btntxt shadow py-3 px-5 md:px-4 md:py-2 text-center rounded-3xl w-full max-w-xs ${selectedSkisForTournament.length < 2 ? "opacity-50 cursor-default" : "hover:opacity-90"
                }`}
            >
              {t('start_test')}
              <SiRundeck className="ml-2" />
            </button>
          </div>


          {/* SkiSort and SkiFilter side by side on medium screens, below on small */}
          <div className="flex justify-between items-end md:space-x-4 px-2">
            <SkiSort onSortChange={handleSortChange} currentSort={sort} />

            <SkiFilter onFilterChange={handleFilterChange} currentFilter={filter} />
          </div>
        </div>



        {sortedAndFilteredSkis.length === 0 && (
          <div className='my-4'>
            <div className='italic'>{t('you_have_no')} {filter} {t('skis')}.</div>
          </div>
        )}
        <div className='flex flex-col space-y-2 mt-4'>
          {sortedAndFilteredSkis.map(ski => (
            <SkiItem
              key={ski.id}
              ski={ski}
              handleCheckboxChange={handleCheckboxChange}
              handleEdit={() => handleEdit(ski)}
              handleDelete={handleDelete}
              handleArchive={handleArchive}
              handleUnarchive={handleUnarchive}
              selectedSkis={selectedSkis}
              expandedSkiId={expandedSkiId}
              toggleDetails={toggleDetails}
            />
          ))}

        </div>

      </div>
    </HelmetProvider>
  );
};

export default SkisPage;
