// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import Spinner from './components/common/Spinner';


const ProtectedRoute = ({ redirectTo }) => {
  const { user, checkingStatus } = useAuth();

  if (checkingStatus) {
    return <Spinner />; // Or some loading spinner
  }

  return user ? <Outlet /> : <Navigate to={redirectTo} />;
};

export default ProtectedRoute;
