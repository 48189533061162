import React from 'react'

const SaveTestInput = ({ name, type, placeholder, onChange, required, value }) => {
    return (
        <div >
            <label className='text-text'> {placeholder} {required && <span className="text-xl absolute text-red-500">*</span>}</label>
            <input className='w-full text-inputtxt p-2 rounded-xl border' type={type} name={name} placeholder={placeholder} onChange={onChange} value={value} required={required} />

        </div>
    );
}

export default SaveTestInput