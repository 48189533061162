const ResultsList = ({ rankings }) => {
    // Sort rankings in descending order of cumulativeScore
    const sortedRankings = [...rankings].sort((a, b) => a.cumulativeScore - b.cumulativeScore);

    let currentRank = 0;
    let lastScore = null;
    let displayedRank = 1;

    const rankedList = sortedRankings.map((item, index) => {
        // Update rank if the score is different
        if (item.cumulativeScore !== lastScore) {
            currentRank = index + 1;
            displayedRank = currentRank;
        } else {
            displayedRank = currentRank; // Keep the same rank for equal scores
        }

        lastScore = item.cumulativeScore; // Update lastScore for the next iteration

        return {
            ...item,
            rank: displayedRank
        };
    });

    return (
        <ul className="space-y-2">
            {rankedList.map(item => (
                <li key={item.serialNumber} className='flex font-semibold justify-between border border-sbtn bg-container shadow rounded-3xl p-2 px-4'>
                    <span className="flex-1">{item.rank}</span>
                    <span className="flex-1 text-center">{item.serialNumber}</span>
                    <span className="flex-1 text-end">Diff - {item.cumulativeScore}</span>
                </li>
            ))}
        </ul>
    );
};

export default ResultsList;
