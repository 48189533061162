import React, { useContext } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { MdLogin } from "react-icons/md";
import { SiRundeck } from "react-icons/si";
import { UserPreferencesContext } from '../../context/UserPreferencesContext';
import logo from '../../assets/logo2.png';
import logoWhite from '../../assets/logo_white.png';

const HomePage = () => {
  const { user, checkingStatus } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { colormode } = useContext(UserPreferencesContext);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>mySkiPark: Organize, Test, Analyze</title>
      </Helmet>
      <div className='flex flex-col items-center min-h-screen p-5 animate-fade-down animate-duration-300'>

        {/* Header */}
        <div className='text-center mt-32 mb-4'>
          <i className='text-lg'>{t('welcome_to')}</i>
          <img
            src={colormode === 'light' ? logo : logoWhite}
            alt="Logo"
            className='h-16 mt-3'
          />
        </div>

        {/* Main Content */}
        <div className='flex-grow text-center'>
          <i className='text-sm'>
            {t('slogan')} {/* This could be a slogan like "Modern Ski Management, Simplified" */}
          </i>
          {!checkingStatus && (
            !user ?
              <div className='mt-20'>
                <button
                  className='flex items-center mx-auto justify-center bg-btn text-btntxt py-3 px-5 rounded-3xl hover:opacity-90 transition duration-300 mb-10'
                  onClick={() => handleNavigation('/signin')}
                >
                  {t('getStarted')}
                  <MdLogin size={18} className='ml-2' />
                </button>
              </div>
              :
              <div className='mt-20'>
                <p className='text-sm mb-2'>{t('hello')} {user.displayName}</p>
                <button
                  className='flex mx-auto items-center justify-center bg-btn text-btntxt py-3 px-5 rounded-3xl hover:opacity-90 transition duration-300'
                  onClick={() => handleNavigation('/skis')}
                >
                  {t('start_test')}
                  <SiRundeck size={18} className='ml-2' />
                </button>
              </div>
          )}
        </div>

        {/* Footer */}
        <footer className='text-xs text-btn mt-auto'>
          &copy; mySkiPark 2024
        </footer>
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
