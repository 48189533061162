import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { useSkis } from '../../hooks/useSkis';
import SkiInput from './SkiInput';
import BackBtn from '../../components/common/BackBtn';
import Spinner from '../../components/common/Spinner/Spinner';
import { formatDateForInput } from '../../helpers/helpers';
import { MdDoubleArrow, MdDelete } from "react-icons/md";

const AddSkisPage = () => {
    const { loading, error, addSki, updateSki } = useSkis();
    const location = useLocation();
    const { state } = location;
    const isEdit = state?.isEdit || false;
    const skiToEdit = state?.ski || null;

    const initialFormData = isEdit ? {
        ...skiToEdit,
        newGrind: '',
        newGrindDate: ''
    } : {
        serialNumber: '',
        brand: '',
        model: '',
        style: '',
        length: '',
        grind: '',
        grindDate: '',
        stiffness: '',
        base: '',
        construction: '',
        comment: '',
        grindHistory: [],
    };

    const [formData, setFormData] = useState(initialFormData);
    const { user } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleDeleteGrind = (index) => {
        const updatedGrindHistory = formData.grindHistory.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            grindHistory: updatedGrindHistory
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) return;

        try {
            const convertToISO = (dateStr) => dateStr ? new Date(dateStr).toISOString() : '';

            if (isEdit) {
                let updatedFormData = { ...formData };

                // Prepare new grind data
                const newGrindEntry = formData.newGrind;
                const newGrindDateISO = convertToISO(formData.newGrindDate);

                // Update grind history if new grind or date is provided
                if (newGrindEntry || formData.newGrindDate) {
                    updatedFormData.grindHistory = [
                        { grind: newGrindEntry, grindDate: newGrindDateISO },
                        ...formData.grindHistory
                    ];
                }

                // Sort grind history by date in descending order
                updatedFormData.grindHistory = updatedFormData.grindHistory.sort((a, b) => new Date(b.grindDate) - new Date(a.grindDate));

                // Set the latest grind as current
                updatedFormData.grind = updatedFormData.grindHistory[0]?.grind || formData.grind;
                updatedFormData.grindDate = updatedFormData.grindHistory[0]?.grindDate || formData.grindDate;

                await updateSki(skiToEdit.id, updatedFormData);
            } else {
                // Add new ski with initial grind history
                const newGrindDateISO = convertToISO(formData.grindDate);

                const updatedFormData = {
                    ...formData,
                    grindDate: newGrindDateISO,
                    grindHistory: [
                        { grind: formData.grind, grindDate: newGrindDateISO }
                    ]
                };

                await addSki(updatedFormData);
            }

            navigate('/skis');
        } catch (error) {
            console.error("Error in form submission: ", error);
        }
    };



    if (loading) return <Spinner />;
    if (error) return <div className='m-2'>Error: {error.message}</div>;

    return (
        <div className="p-4">
            <form onSubmit={handleSubmit}>
                <SkiInput
                    label={t('serial_number')}
                    type="number"
                    name="serialNumber"
                    value={formData.serialNumber}
                    onChange={handleChange}
                    placeholder={t('serial_number')}
                    required
                />
                <SkiInput
                    label={t('style')}
                    name="style"
                    value={formData.style}
                    onChange={handleChange}
                    placeholder={t('style')}
                    isStyle
                    required

                />
                <SkiInput
                    label={t('brand')}
                    name="brand"
                    value={formData.brand}
                    onChange={handleChange}
                    placeholder={t('brand')}
                    required
                />
                <SkiInput
                    label={t('model')}
                    name="model"
                    value={formData.model}
                    onChange={handleChange}
                    placeholder={t('model')}
                />
                <div className={` ${isEdit && 'bg-container shadow border border-sbtn rounded-xl my-4 p-4'}`}>
                    {isEdit && (<h3 className='self-start text-xl mb-4 font-semibold'>{t('change_grind')}</h3>)}

                    <div className='flex flex-col md:flex-row justify-between items-center'>
                        <div className='w-full'>
                            <SkiInput
                                label={t('grind')}
                                name="grind"
                                value={formData.grind}
                                onChange={handleChange}
                                placeholder={t('grind')}
                                required
                                disabled={isEdit}
                            />
                            <SkiInput
                                label={t('grind_date')}
                                type="date"
                                name="grindDate"
                                value={formatDateForInput(formData.grindDate)}
                                onChange={handleChange}
                                placeholder={t('grind_date')}
                                required
                                disabled={isEdit}
                            />
                        </div>
                        {isEdit && (
                            <div className=' mx-4 my-2 rotate-90 md:rotate-0'>
                                <MdDoubleArrow size={25} />
                            </div>
                        )}
                        {isEdit && (
                            <div className='w-full'>
                                <SkiInput
                                    label={t('new_grind')}
                                    name="newGrind"
                                    value={formData.newGrind || ''}
                                    onChange={handleChange}
                                    placeholder={t('new_grind')}
                                />
                                <SkiInput
                                    label={t('new_grind_date')}
                                    type="date"
                                    name="newGrindDate"
                                    value={formData.newGrindDate || ''}
                                    onChange={handleChange}
                                    placeholder={t('new_grind_date')}
                                />
                            </div>
                        )}
                    </div>

                    {isEdit && formData.grindHistory.length > 0 && (
                        <div className=" p-4 flex flex-col items-center">
                            <h3 className="text-xl font-semibold mb-4">{t('grind_history')}</h3>
                            <ul className='space-y-2'>
                                {formData.grindHistory.map((entry, index) => (
                                    <li key={index} className="flex justify-between items-center">
                                        <span>{entry.grind} - {new Date(entry.grindDate).toLocaleDateString()}</span>
                                        <button
                                            type="button"
                                            onClick={() => handleDeleteGrind(index)}
                                            className="bg-delete hover:opacity-90 rounded-full ml-4 p-2 cursor-pointer"
                                        >
                                            <MdDelete className="text-white" />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>



                <SkiInput
                    label={t('length')}
                    type="range"
                    name="length"
                    value={formData.length}
                    onChange={handleChange}
                    min={170}
                    max={220}
                    step={1}
                    placeholder={t('length')}
                />

                <SkiInput
                    label={t('stiffness')}
                    name="stiffness"
                    value={formData.stiffness}
                    onChange={handleChange}
                    placeholder={t('stiffness')}
                />
                <SkiInput
                    label="Base"
                    name="base"
                    value={formData.base}
                    onChange={handleChange}
                    placeholder="Base"
                />
                <SkiInput
                    label={t('construction')}
                    name="construction"
                    value={formData.construction}
                    onChange={handleChange}
                    placeholder={t('construction')}
                />
                <div>
                    <label className='font-medium'>{t('comment')}:</label>
                    <textarea
                        name="comment"
                        className='mt-1 text-black w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500'
                        value={formData.comment}
                        onChange={handleChange}
                        placeholder="Comment"
                    ></textarea>
                </div>
                <div className="flex space-x-2 my-4">
                    <button type="submit" className='bg-btn text-btntxt font-semibold py-3 px-5 shadow rounded-3xl'>
                        {t('save')}
                    </button>
                    <BackBtn />
                </div>
            </form>
        </div>
    );
};

export default AddSkisPage;
