import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar/ProgressBar';
import SkiMatchup from './SkiMatchup/SkiMatchup';
import TestResultPage from './TestResultPage/TestSummaryPage';


const TestingPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const selectedSkis = location.state?.selectedSkis || [];

    const [currentRound, setCurrentRound] = useState([]);
    const [roundsHistory, setRoundsHistory] = useState([]);

    const [tournamentOver, setTournamentOver] = useState(false);
    const [roundNumber, setRoundNumber] = useState(1);
    const [matchScores, setMatchScores] = useState({});
    const [lossPath, setLossPath] = useState({});
    const [skiSerialNumbers, setSkiSerialNumbers] = useState({});

    const [potentialSwapTarget, setPotentialSwapTarget] = useState(null);

    const totalRounds = Math.ceil(Math.log2(selectedSkis.length));
    let progressPercentage;

    if (tournamentOver) {
        progressPercentage = 100;
    } else {
        progressPercentage = ((roundNumber - 1) / totalRounds) * 100;
    }

    const onDragUpdate = (update) => {
        if (!update.destination) {
            setPotentialSwapTarget(null);
            return;
        }

        const sourceMatchId = parseInt(update.source.droppableId.split('-')[1]);
        const destinationMatchId = parseInt(update.destination.droppableId.split('-')[1]);

        // Highlight only if the source and destination match IDs are different
        if (sourceMatchId !== destinationMatchId) {
            const destinationMatch = currentRound.find(match => match.id === destinationMatchId);
            if (destinationMatch && destinationMatch.skis.length === 2) {
                setPotentialSwapTarget(destinationMatch.skis[update.destination.index]);
            } else {
                setPotentialSwapTarget(null);
            }
        } else {
            setPotentialSwapTarget(null);
        }
    };


    const onDragEnd = (result) => {
        setPotentialSwapTarget(null);
        const { source, destination } = result;

        // Do nothing if the item is dropped outside a droppable area or if it's the same position
        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
            return;
        }

        const sourceMatchId = parseInt(source.droppableId.split('-')[1]);
        const destinationMatchId = parseInt(destination.droppableId.split('-')[1]);

        const newCurrentRound = [...currentRound];
        const sourceMatch = newCurrentRound.find(match => match.id === sourceMatchId);
        const destinationMatch = newCurrentRound.find(match => match.id === destinationMatchId);

        const movedSkiPair = sourceMatch.skis.splice(source.index, 1)[0];
        const adjustedDestinationIndex = Math.min(destination.index, 1);

        if (sourceMatchId !== destinationMatchId) {
            // Handle movement between different matches
            if (destinationMatch.skis.length === 2) {
                const swappedSkiPair = destinationMatch.skis.splice(adjustedDestinationIndex, 1, movedSkiPair)[0];
                sourceMatch.skis.splice(source.index, 0, swappedSkiPair);

                // Swap scores between the skis
                const sourceMatchScores = { ...matchScores[sourceMatchId] };
                const destinationMatchScores = { ...matchScores[destinationMatchId] };

                // Move score from destination to source
                sourceMatchScores[swappedSkiPair.id] = destinationMatchScores[swappedSkiPair.id];
                sourceMatchScores[movedSkiPair.id] = destinationMatchScores[movedSkiPair.id];

                // Update the destination with the new score
                destinationMatchScores[movedSkiPair.id] = matchScores[sourceMatchId][movedSkiPair.id];

                // Update the scores state
                setMatchScores({
                    ...matchScores,
                    [sourceMatchId]: sourceMatchScores,
                    [destinationMatchId]: destinationMatchScores
                });
            } else {
                destinationMatch.skis.splice(adjustedDestinationIndex, 0, movedSkiPair);

                // Move the score to the destination match
                const newMatchScores = { ...matchScores };
                newMatchScores[destinationMatchId] = {
                    ...newMatchScores[destinationMatchId],
                    [movedSkiPair.id]: matchScores[sourceMatchId][movedSkiPair.id]
                };

                // Remove the score from the source match
                delete newMatchScores[sourceMatchId][movedSkiPair.id];

                setMatchScores(newMatchScores);
            }
        } else {
            // Handle reordering within the same match
            sourceMatch.skis.splice(destination.index, 0, movedSkiPair);
        }

        // Update the current round state
        setCurrentRound(newCurrentRound);
    };




    useEffect(() => {
        initializeTournament(selectedSkis);
    }, [selectedSkis]);

    const initializeTournament = (skis) => {
        const pairs = pairSkisForMatches(skis);
        setCurrentRound(pairs);
        initializeScores(pairs);
        mapSerialNumbers(skis);
    };

    const pairSkisForMatches = (skis) => {
        return skis.reduce((pairs, ski, index) => {
            if (index % 2 === 0) pairs.push({ id: index / 2, skis: [ski], winner: null });
            else pairs[pairs.length - 1].skis.push(ski);
            return pairs;
        }, []);
    };

    const initializeScores = (pairedSkis) => {
        const initialScores = pairedSkis.reduce((scores, match) => {
            scores[match.id] = match.skis.reduce((ms, ski) => {
                ms[ski.id] = 0;
                return ms;
            }, {});
            return scores;
        }, {});
        setMatchScores(initialScores);
        setRoundNumber(1);
    };

    const mapSerialNumbers = (skis) => {
        const serialNumbers = skis.reduce((nums, ski) => {
            nums[ski.id] = ski.serialNumber;
            return nums;
        }, {});
        setSkiSerialNumbers(serialNumbers);
    };

    const updateMatchScore = (matchId, skiId, score) => {
        setMatchScores(prevScores => ({
            ...prevScores,
            [matchId]: { ...prevScores[matchId], [skiId]: score }
        }));
    };

    const handleSubmitRound = () => {
        // Validate that each match has one ski with a score of 0
        for (const match of currentRound) {
            const matchScore = matchScores[match.id];
            const [ski1, ski2] = match.skis;

            if (ski2) {
                const ski1Score = matchScore[ski1.id];
                const ski2Score = matchScore[ski2.id];

                if (ski1Score > 0 && ski2Score > 0) {
                    alert(`Both skis in Test ${match.id + 1} have scores greater than 0. One ski must have a score of 0 to proceed.`);
                    return; // Exit the function if the validation fails
                }
            }
        }

        // Proceed with the normal submission process if validation passes
        let nextRoundSkis = [];
        let roundOutcomes = {};

        for (const match of currentRound) {
            const matchScore = matchScores[match.id];
            const [ski1, ski2] = match.skis;

            // Check if ski2 exists (in case of an odd number of skis)
            if (!ski2) {
                nextRoundSkis.push(ski1);
                continue; // Skip to the next iteration
            }

            let winner;
            if (matchScore[ski1.id] === matchScore[ski2.id]) {
                winner = decideTiebreaker(ski1, ski2);
                if (winner === null) {
                    // Tiebreaker was cancelled, cancel the submit process
                    return; // Exit the function
                }
            } else {
                winner = matchScore[ski1.id] < matchScore[ski2.id] ? ski1 : ski2;
            }

            const loser = winner === ski1 ? ski2 : ski1;
            const scoreDiff = Math.abs(matchScore[ski1.id] - matchScore[ski2.id]);

            nextRoundSkis.push(winner);
            roundOutcomes[loser.id] = { lostTo: winner.id, scoreDiff };
        }

        setLossPath(prevPath => ({ ...prevPath, ...roundOutcomes }));
        prepareNextRound(nextRoundSkis);
    };



    const prepareNextRound = (nextRoundSkis) => {
        setRoundsHistory(prevHistory => [...prevHistory, { round: currentRound, scores: matchScores, roundNumber }]);

        if (nextRoundSkis.length === 1) {
            setTournamentOver(true);
        } else {
            const pairedSkis = pairSkisForMatches(nextRoundSkis);
            setCurrentRound(pairedSkis);
            initializeScores(pairedSkis);
            setRoundNumber(prev => prev + 1);
        }
    };

    const goBackToPreviousRound = () => {
        const history = [...roundsHistory]; // Local copy

        if (history.length === 0) {
            return; // No previous rounds to return to
        }

        const previousRoundData = history[history.length - 1];
        setCurrentRound(previousRoundData.round);
        setMatchScores(previousRoundData.scores);
        setRoundNumber(previousRoundData.roundNumber);
        setTournamentOver(false);

        // Reset the lossPath for skis involved in all subsequent matches
        const newLossPath = { ...lossPath };
        previousRoundData.round.forEach(match => {
            match.skis.forEach(ski => {
                delete newLossPath[ski.id];
            });
        });
        setLossPath(newLossPath);

        // Remove the last item from the history
        setRoundsHistory(history.slice(0, -1));
    };




    const calculateCumulativeScore = (skiId, visited = new Set()) => {
        if (!lossPath[skiId] || visited.has(skiId)) return 0;
        visited.add(skiId);

        const { lostTo, scoreDiff } = lossPath[skiId];
        return scoreDiff + calculateCumulativeScore(lostTo, visited);
    };

    const calculateRankings = () => {
        return selectedSkis.map(ski => ({
            serialNumber: skiSerialNumbers[ski.id],
            cumulativeScore: calculateCumulativeScore(ski.id),
            skiId: ski.id
        }))
            .sort((a, b) => a.cumulativeScore - b.cumulativeScore);
    };

    const decideTiebreaker = (ski1, ski2) => {
        let winner;
        while (true) {
            winner = prompt("Tiebreak! Select the winner (enter serial number): " + ski1.serialNumber + " or " + ski2.serialNumber);

            if (winner === null) {
                // Prompt was cancelled
                return null; // Return null to indicate cancellation
            } else if (winner === ski1.serialNumber) {
                return ski1;
            } else if (winner === ski2.serialNumber) {
                return ski2;
            } else {
                alert("Invalid input. Please enter a valid serial number.");
            }
        }
    };


    return (
        <HelmetProvider>
            <Helmet>
                <title>mySkiPark: Testing</title>
            </Helmet>
            <DragDropContext onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>

                {!tournamentOver && (
                    <div className='p-4'>
                        <ProgressBar progress={progressPercentage} />

                        <h2 className='text-dominant text-center text-xl font-semibold'>{t('round')} {roundNumber}</h2>
                        {currentRound.map(match => (
                            <SkiMatchup
                                key={match.id}
                                match={match}
                                scores={matchScores[match.id] || {}}
                                potentialSwapTarget={potentialSwapTarget}
                                onScoreChange={(skiId, score) => updateMatchScore(match.id, skiId, score)}
                            />
                        ))}

                        <div className='flex justify-between my-4'>
                            {roundNumber > 1 && (
                                <>
                                    <button className='px-5 py-3 bg-sbtn rounded-3xl' onClick={goBackToPreviousRound}>{t('go_back')}</button>
                                </>


                            )}
                            <button className='bg-btn text-btntxt font-semibold px-5 py-3 rounded-3xl shadow' onClick={handleSubmitRound}>{t('submit_round')}</button>
                        </div>

                        <h3 className='text-lg my-2'>Tips</h3>
                        <ul className='list-disc px-4'>
                            <li>Touch and hold to reorganize</li>
                            <li>Winner should always have 0</li>
                        </ul>

                    </div>
                )}



                {/* Tournament Results */}
                {tournamentOver && (
                    <TestResultPage
                        skis={selectedSkis}
                        calculateRankings={calculateRankings}
                        goBack={goBackToPreviousRound}
                    />
                )}


            </DragDropContext>
        </HelmetProvider>
    );
};

export default TestingPage;
