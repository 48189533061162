import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    updateProfile,
    signOut
} from 'firebase/auth';
import { auth, db } from './config';
import { doc, setDoc } from 'firebase/firestore';

const registerWithEmailAndPassword = async (email, password, username) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await updateProfile(userCredential.user, { displayName: username });
        
        // Set default preferences in Firestore
        const defaultPreferences = {
            themePreference: 'light',  // Default theme
            languagePreference: 'en'  // Default language
        };
        await setDoc(doc(db, 'users', userCredential.user.uid), {
            preferences: defaultPreferences
        }, { merge: true });

        return userCredential;
    } catch (error) {
        console.error('Registration Error:', error.message);
        throw error;
    }
};


const loginWithEmailAndPassword = async (email, password) => {
    try {
        return await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
        console.error('Login Error:', error.message);
        throw error;
    }
};

// Update user's profile details
const updateProfileDetails = async (user, newDisplayName, photoURL = null) => {
    try {
        // Update only the provided fields
        const updateData = {};
        if (newDisplayName !== null) updateData.displayName = newDisplayName;
        if (photoURL !== null) updateData.photoURL = photoURL;

        await updateProfile(user, updateData);
    } catch (error) {
        console.error('Profile Update Error:', error.message);
        throw error;
    }
};

// Send password reset email
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (error) {
        console.error('Password Reset Error:', error.message);
        throw error;
    }
};

const signOutUser = async () => {
    try {
        await signOut(auth);
    } catch (error) {
        console.error('Sign Out Error:', error.message);
        throw error;
    }
};



// Export only the used functions
export {
    registerWithEmailAndPassword,
    loginWithEmailAndPassword,
    updateProfileDetails,
    sendPasswordReset,
    signOutUser
};
