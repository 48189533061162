import { useState, useEffect } from 'react';
import { getUserSkis, addUserSkis, updateUserSki, deleteUserSki} from '../firebase/firestoreFunctions';
import { useAuth } from '../context/AuthContext';

export const useSkis = () => {
    const [skis, setSkis] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        if (!user) return;
        setLoading(true);
        getUserSkis(user.uid).then(fetchedSkis => {
            setSkis(fetchedSkis);
        }).catch(error => {
            setError(error);
        }).finally(() => {
            setLoading(false);
        });
    }, [user]);

    const addSki = async (skiData) => {
        if (!user) return;
        setLoading(true);
        try {
            const skiId = await addUserSkis(user.uid, skiData);
            setSkis([...skis, { ...skiData, id: skiId }]);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const updateSki = async (skiId, updatedSkiData) => {
        if (!user) return;
        setLoading(true);
        try {
            await updateUserSki(user.uid, skiId, updatedSkiData);
            setSkis(skis.map(ski => ski.id === skiId ? { ...ski, ...updatedSkiData } : ski));
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const deleteSki = async (skiId) => {
        if (!user) return;
        setLoading(true);
        try {
            await deleteUserSki(user.uid, skiId);
            setSkis(skis.filter(ski => ski.id !== skiId));
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };


    const updateSkisList = (newSkis) => {
        setSkis(newSkis);
    };

    return { skis, loading, error, addSki, updateSki, deleteSki, updateSkisList };
};
