import React from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import './i18n';
import AppRoutes from './routes/Routes';
import { AuthProvider } from './context/AuthContext';
import { UserPreferencesProvider } from './context/UserPreferencesContext';
import Header from './components/layout/Header';
import Navigation from './components/layout/Navigation';
import Layout from './routes/Layout';

function App() {
  return (
    <AuthProvider>
      <UserPreferencesProvider>
        <Router>
          <Layout /> {/* Move the conditional logic to this Layout component */}
        </Router>
      </UserPreferencesProvider>
    </AuthProvider>
  );
}

export default App;
