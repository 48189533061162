import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateTournamentResult } from '../../../firebase/firestoreFunctions';
import { useAuth } from '../../../context/AuthContext';
import SaveTestInput from '../../TestingPage/TestResultPage/SaveTestInput/SaveTestInput';
import { useTranslation } from 'react-i18next';
import BackBtn from '../../../components/common/BackBtn/BackBtn';
import { formatDateForInputWithTime } from '../../../helpers/helpers';


const EditResultPage = () => {
    const { t } = useTranslation();
    const { resultId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAuth();

    const [resultData, setResultData] = useState(location.state?.resultData || {
        location: '',
        style: '',
        temperature: '',
        snowTemperature: '',
        humidity: '',
        snowType: '',
        comment: '',
        timestamp: new Date().toISOString(),  // Default to current time
        rankings: []
    });





    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith("score-")) {
            const index = parseInt(name.split("-")[1]);
            const updatedRankings = [...resultData.rankings];
            const updatedRanking = { ...updatedRankings[index], score: Number(value) };
            updatedRankings[index] = updatedRanking;
            setResultData({ ...resultData, rankings: updatedRankings });
        } else if (name === 'date') {
            const newDate = new Date(value);
            if (!isNaN(newDate.getTime())) {  // Check if newDate is a valid date
                setResultData(prevData => ({
                    ...prevData,
                    timestamp: newDate.toISOString()
                }));
            } else {
                console.error('Invalid date provided:', value);
            }
        } else {
            setResultData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let timestampToUse;

            // Check if the timestamp is a Firestore Timestamp object
            if (resultData.timestamp && typeof resultData.timestamp === 'object' && 'seconds' in resultData.timestamp && 'nanoseconds' in resultData.timestamp) {
                // Convert Firestore Timestamp to JavaScript Date
                timestampToUse = new Date(resultData.timestamp.seconds * 1000 + resultData.timestamp.nanoseconds / 1000000);
            } else if (typeof resultData.timestamp === 'string' || typeof resultData.timestamp === 'number') {
                // Handle as ISO string or Unix timestamp
                timestampToUse = new Date(resultData.timestamp);
            } else {
                throw new Error('Invalid timestamp');
            }

            // Validate the date
            if (isNaN(timestampToUse.getTime())) {
                throw new Error('Invalid timestamp');
            }

            await updateTournamentResult(user.uid, resultId, {
                ...resultData,
                timestamp: timestampToUse.toISOString()  // Convert to ISO string for Firebase storage
            });

            navigate('/results');
        } catch (error) {
            console.error("Error updating result:", error);
        }
    };






    return (
        <HelmetProvider>
            <Helmet>
                <title>mySkiPark: Edit Result</title>
            </Helmet>
            <div className='p-4'>
                <div className='flex justify-between'>
                    <h2 className='mb-4 text-3xl font-semibold text-text '>{t('edit_test')}</h2>
                </div>


                <form onSubmit={handleSubmit} >

                    {/* Inputs for each ski's ranking/score */}
                    {resultData.rankings.map((ranking, index) => (
                        <div key={index} className='flex flex-col mb-2'>
                            <label className='font-semibold'>{`${ranking.serialNumber} - ${ranking.grind}`}</label>
                            <SaveTestInput
                                type="number"
                                name={`score-${index}`}
                                onChange={handleInputChange}
                                value={ranking.score}
                            />
                        </div>
                    ))}
                    <h3 className='mt-5 mb-2 text-2xl font-semibold text-dominant '>Test data</h3>

                    <div className='space-y-2'>
                        <SaveTestInput type="text" name="location" placeholder="Enter location" onChange={handleInputChange} value={resultData.location} required={true} />
                        <SaveTestInput type="text" name="style" placeholder="Enter style" onChange={handleInputChange} value={resultData.style} required={true} />
                        <SaveTestInput type="number" name="temperature" placeholder="Enter temperature" onChange={handleInputChange} value={resultData.temperature} />
                        <SaveTestInput type="number" name="snowTemperature" placeholder="Enter snow temperature" onChange={handleInputChange} value={resultData.snowTemperature} />
                        <SaveTestInput type="number" name="humidity" placeholder="Enter humidity" onChange={handleInputChange} value={resultData.humidity} />
                        <SaveTestInput type="text" name="snowType" placeholder="Enter snow type" onChange={handleInputChange} value={resultData.snowType} />
                        <SaveTestInput type="text" name="comment" placeholder="Enter comment" onChange={handleInputChange} value={resultData.comment} />
                        <SaveTestInput type="datetime-local" name="date" placeholder="Select date and time" onChange={handleInputChange} value={formatDateForInputWithTime(resultData.timestamp)} required={true} />
                    </div>
                    <div className='flex space-x-4 my-5'>
                        <button type="submit" className='bg-btn px-5 py-3 rounded-3xl w-fit font-semibold text-btntxt '>Save Changes</button>
                        <BackBtn />

                    </div>
                </form>

            </div>

        </HelmetProvider>

    );
};

export default EditResultPage;
