// SubNavigation.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useProfileActions } from '../../../hooks/useProfileActions';
import { useAuth } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { FaUser, FaSignOutAlt } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { MdFeedback } from "react-icons/md";

const SubNavigation = ({ isVisible, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { signOut } = useProfileActions(user);

  if (!isVisible) return null;

  const handleItemClick = (path) => {
    navigate(path);
    onClose();
  };

  return (
    <ul className="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2/3 space-y-3 cursor-pointer text-btntxt font-semibold md:static md:m-2 md:h-fit md:w-full animate-fade md:animate-fade-down animate-duration-300 md:animate-duration-200">
      <li className="bg-btn shadow rounded-3xl flex justify-between items-center p-4 hover:opacity-90" onClick={() => handleItemClick('/account')}>
        {t('account')}<FaUser />
      </li>
      <li className="bg-btn shadow rounded-3xl flex justify-between items-center p-4 hover:opacity-90" onClick={() => handleItemClick('/settings')}>
        {t('settings')}<IoSettingsSharp />
      </li>
      <li className="bg-btn shadow rounded-3xl flex justify-between items-center p-4 hover:opacity-90" onClick={() => handleItemClick('/contact')}>
        {t('contact')}<MdFeedback />
      </li>
      {user && (<li className="bg-btn shadow rounded-3xl flex justify-between items-center p-4 hover:opacity-90" onClick={() => { signOut(navigate); onClose(); }}>
        {t('signOut')}<FaSignOutAlt />
      </li>)}

    </ul>
  );
};

export default SubNavigation;
