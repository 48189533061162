import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { FaCloud, FaCloudRain } from "react-icons/fa";
import { BsCloudSnowFill, BsSunFill } from "react-icons/bs";
import { FaTemperatureThreeQuarters, FaLocationDot } from 'react-icons/fa6';
import { MdDarkMode } from "react-icons/md";

const Weather = () => {
  const [weather, setWeather] = useState(null);
  const [locationError, setLocationError] = useState(false); // Track location permission
  const { user } = useAuth();

  const getPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  const fetchWeather = async (latitude, longitude) => {
    const apiKey = process.env.REACT_APP_OPENWEATHER_API_KEY; // Replace with your OpenWeatherMap API Key
    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=${apiKey}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const currentTime = new Date().getTime() / 1000;
      const sunrise = data.sys.sunrise;
      const sunset = data.sys.sunset;

      const isDayTime = currentTime > sunrise && currentTime < sunset;
      setWeather({
        ...data,
        isDayTime: isDayTime,
      });
    } catch (error) {
      console.error("Could not fetch weather data", error);
    }
  };

  const requestLocation = () => {
    setLocationError(false); // Reset the error before requesting again
    getPosition()
      .then((position) => {
        fetchWeather(position.coords.latitude, position.coords.longitude);
      })
      .catch((err) => {
        console.error("Could not get the user's position", err);
        setLocationError(true); // Set error to show location request button
      });
  };

  useEffect(() => {
    requestLocation(); // Try fetching the location on initial render
  }, []);

  const getWeatherIcon = (weatherCondition, isDayTime) => {
    if (isDayTime) {
      switch (weatherCondition) {
        case 'Clouds':
          return <FaCloud size={15} />;
        case 'Rain':
          return <FaCloudRain size={15} />;
        case 'Snow':
          return <BsCloudSnowFill size={15} />;
        case 'Clear':
          return <BsSunFill size={15} />;
        default:
          return "";
      }
    } else {
      return <MdDarkMode size={15} />;
    }
  };

  return user ? (
    weather ? (
      <div className="flex flex-1 md:justify-end text-xs space-x-2">
        <div className='flex'>
          <FaLocationDot size={15} />
          <h2>{weather.name}</h2>
        </div>

        <div className='flex'>
          <FaTemperatureThreeQuarters size={15} />
          <h2>{(weather.main.temp).toFixed(1)}°C</h2>
        </div>

        {getWeatherIcon(weather.weather[0].main, weather.isDayTime)}
      </div>
    ) : (
      locationError && (
        <div className="flex-1 text-end hidden md:block shrink-0 text-xs space-x-2">
          <p>Share location in browser</p>
        </div>
      )
    )
  ) : null;
};

export default Weather;
