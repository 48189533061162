import { collection, addDoc, getDoc, doc, where, query, deleteDoc, getDocs, updateDoc, orderBy, writeBatch } from 'firebase/firestore';
import { db, storage } from './config';
import { ref, deleteObject } from 'firebase/storage';


//For adding new skis
export const addUserSkis = async (userId, skiData) => {
  try {
    const docRef = await addDoc(collection(db, `users/${userId}/skis`), skiData);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
    throw new Error(error);
  }
};

export const getUserSkis = async (userId) => {
  const skiCollectionRef = collection(db, `users/${userId}/skis`);
  // Adding orderBy to sort by serialNumber
  const q = query(skiCollectionRef, orderBy("serialNumber"));
  const skiDocsSnapshot = await getDocs(q);
  
  return skiDocsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export const getSkiTests = async (userId, testId) => {
  const testDocRef = doc(db, `users/${userId}/testResults/${testId}`);
  // Adding orderBy to sort by serialNumber
  //const q = query(testCollectionRef, where("rankings", "array-contains", testId));
  const testDocSnapshot = await getDoc(testDocRef);
  return testDocSnapshot.data();
}

export const getSkiData = async (userId, skiId) => {
  try {
      const skiDocRef = doc(db, `users/${userId}/skis`, skiId);
      const skiDocSnapshot = await getDoc(skiDocRef);
      

      if (skiDocSnapshot.exists()) {
          return skiDocSnapshot.data(); // Returns the ski data
      } else {
          return null; // Or handle as needed if the ski does not exist
      }
  } catch (error) {
      console.error("Error getting ski data: ", error);
      throw error; // Or handle it as needed
  }
};

export const updateUserSki = async (userId, skiId, skiData) => {
  const skiDocRef = doc(db, `users/${userId}/skis`, skiId);
  await updateDoc(skiDocRef, skiData); 
};

export const updateSkiRanking = async (userId, skiId, testId) => {
  const skiDocRef = doc(db, `users/${userId}/skis`, skiId);
  const skiDoc = await getDoc(skiDocRef);
  const skiData = skiDoc.data();

  let testIds = skiData.testIds || [];

  // Add testId to testIds array if it's not already present
  if (!testIds.includes(testId)) {
    testIds.push(testId);
  }

  await updateDoc(skiDocRef, { testIds });
};

export const fetchSkisForTournament = async (userId, tournamentId) => {
  const skiCollectionRef = collection(db, `users/${userId}/skis`);
  const queryCondition = query(skiCollectionRef, where("testIds", "array-contains", tournamentId));
  const skiDocsSnapshot = await getDocs(queryCondition);
  return skiDocsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};


// In firestoreFunctions.js

export const deleteTournamentAndRelatedRankings = async (userId, tournamentId) => {
  try {
    // Fetch skis associated with the tournament
    const skis = await fetchSkisForTournament(userId, tournamentId);

    // Update each ski to remove the tournamentId from testIds array
    for (const ski of skis) {
      const updatedTestIds = ski.testIds.filter(testId => testId !== tournamentId);

      // Update the ski document in Firestore
      const skiDocRef = doc(db, `users/${userId}/skis`, ski.id);
      await updateDoc(skiDocRef, { testIds: updatedTestIds });
    }

    // Delete the tournament result
    await deleteTournamentResult(userId, tournamentId);
  } catch (error) {
    console.error("Error in deleting tournament and related rankings:", error);
    throw error; // Or handle it as needed
  }
};

export const deleteTournamentResult = async (userId, resultId) => {
  const resultDocRef = doc(db, `users/${userId}/testResults`, resultId);
  await deleteDoc(resultDocRef);
};



export const deleteUserSki = async (userId, skiId) => {
  const skiDocRef = doc(db, `users/${userId}/skis`, skiId);
  await deleteDoc(skiDocRef);
};

export const addUserTournamentResults = async (userId, tournamentData, additionalData) => {
  try {
    // Create a new timestamp
    let timestampToUse = new Date();

    // Combine tournamentData and additionalData
    const fullData = { ...tournamentData, ...additionalData, timestamp: timestampToUse.toISOString() };

    // Add document to Firestore
    const docRef = await addDoc(collection(db, `users/${userId}/testResults`), fullData);
    console.log("User-specific tournament results written with ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding user-specific tournament results: ", error);
    throw new Error(error);
  }
};



export const updateTournamentResult = async (userId, resultId, updatedData) => {
  const resultDocRef = doc(db, `users/${userId}/testResults`, resultId);
  await updateDoc(resultDocRef, updatedData);
};

export const getTournamentResult = async (userId, resultId) => {
  try {
      const resultDocRef = doc(db, `users/${userId}/testResults`, resultId);
      const resultDocSnapshot = await getDoc(resultDocRef);

      if (resultDocSnapshot.exists()) {
          return { id: resultDocSnapshot.id, ...resultDocSnapshot.data() };
      } else {
          console.log("No such tournament result!");
          return null;
      }
  } catch (error) {
      console.error("Error getting tournament result: ", error);
      throw error;
  }
};


export const addContactFormSubmission = async (subject, message) => {
  try {
    const docRef = await addDoc(collection(db, 'contact'), {
      subject,
      message,
      timestamp: new Date(), // Optionally add a timestamp or other metadata
    });

    console.log("Document written with ID: ", docRef.id);
    return docRef.id; // Return the document ID in case it's needed
  } catch (error) {
    console.error("Error adding document: ", error);
    throw new Error(error); // Rethrow the error to handle it in the component
  }
};

// Delete profile picture from Firebase Storage
const deleteProfilePicture = async (userId) => {
  const imageRef = ref(storage, `profilePictures/${userId}`);
  try {
    await deleteObject(imageRef);
    console.log('Profile picture deleted successfully.');
  } catch (error) {
    if (error.code === 'storage/object-not-found') {
      console.log('No profile picture to delete.');
    } else {
      console.error('Error deleting profile picture:', error.message);
      throw error;
    }
  }
};

// Delete all documents in a collection
const deleteCollection = async (userId, collectionPath) => {
  const batch = writeBatch(db);
  const collectionRef = collection(db, `users/${userId}/${collectionPath}`);
  const snapshot = await getDocs(collectionRef);
  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();
};

// Consolidated function to delete user data and authentication record
export const deleteAccount = async (userId, authDeleteFunction) => {
  try {
    await deleteProfilePicture(userId);
    await deleteCollection(userId, 'skis');
    await deleteCollection(userId, 'testResults');

    // Additionally, delete the user's main document in Firestore
    const userDocRef = doc(db, `users/${userId}`);
    await deleteDoc(userDocRef);
    console.log('User document deleted successfully.');

    await authDeleteFunction();  // Delete authentication record
    console.log('User account and associated data deleted successfully.');
  } catch (error) {
    console.error('Error deleting account:', error.message);
    throw error;
  }
};