import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { useAuth } from '../../../context/AuthContext';
import { getSkiTests } from '../../../firebase/firestoreFunctions';

const SkiDetail = ({ ski }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const [chartData, setChartData] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [containerWidth, setContainerWidth] = useState('100%');
    const chartContainerRef = useRef(null);

    const formatRankingsForChart = async (testIds) => {
        const allRankings = await Promise.all(
            testIds.map(async (testId) => {
                const rankingData = await getSkiTests(user.uid, testId);
                const sortedRankings = rankingData.rankings.sort((a, b) => a.score - b.score);
                const rankingsWithRank = sortedRankings.map((ranking, index) => ({
                    ...ranking,
                    rank: index + 1,
                    numberOfSkiesInTest: rankingData.rankings.length,
                    testDate: new Date(rankingData.timestamp).getTime(), // Use timestamp
                    location: rankingData.location,
                    temp: rankingData.temperature,
                }));

                return rankingsWithRank;
            })
        );

        let currentSkiRankings = allRankings.flat().filter(ranking => ranking.skiId === ski.id);

        currentSkiRankings.sort((a, b) => a.testDate - b.testDate);

        const chartData = currentSkiRankings.map(ranking => ({
            testDate: ranking.testDate,
            place: ranking.rank,
            total: ranking.numberOfSkiesInTest,
            location: ranking.location,
            temp: ranking.temp,
        }));

        return chartData;
    };

    useEffect(() => {
        if (ski.testIds) {
            formatRankingsForChart(ski.testIds).then(data => {
                setChartData(data);
                if (data.length > 0) {
                    const latestYear = new Date(Math.max(...data.map(d => d.testDate))).getFullYear().toString();
                    setSelectedYear(latestYear);
                }
            });
        }
    }, [ski.testIds]);

    useEffect(() => {
        if (chartContainerRef.current) {
            if (selectedYear) {
                // Adjust width to 100% for specific year view
                setContainerWidth('100%');
                chartContainerRef.current.scrollLeft = 0; // Reset scroll position
            } else {
                // Adjust width for "All" view to allow scrolling
                setContainerWidth('200%'); // or adjust based on your needs

                // Use a timeout to ensure that scroll is set after rendering
                setTimeout(() => {
                    if (chartContainerRef.current) {
                        chartContainerRef.current.scrollLeft = chartContainerRef.current.scrollWidth; // Scroll to the end
                    }
                }, 400); // Small timeout to allow rendering to complete
            }
        }
    }, [chartData, selectedYear]);


    if (!ski) return null;

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip bg-background p-4 shadow-xl ">
                    <p className='font-semibold'>{`P${payload[0].value} (out of ${payload[0].payload.total})`}</p>
                    <p>{`Temperature: ${payload[0].payload.temp}°C`}</p>
                    <p>{`Location: ${payload[0].payload.location}`}</p>
                    <p className="label">{`Test Date : ${new Date(label).toLocaleDateString()}`}</p>
                </div>
            );
        }

        return null;
    };

    const filteredChartData = selectedYear
        ? chartData.filter(data => {
            const date = new Date(data.testDate);
            const year = date.getFullYear().toString();
            return year === selectedYear;
        })
        : chartData;

    const filteredGrindHistory = selectedYear
        ? ski.grindHistory.filter(data => {
            const grindDate = new Date(data.grindDate);
            const grindYear = grindDate.getFullYear().toString();
            return grindYear === selectedYear;
        })
        : ski.grindHistory;

    // Compute min and max dates for filtering
    const allDates = [
        ...chartData.map(data => data.testDate),
        ...filteredGrindHistory.map(data => new Date(data.grindDate).getTime())
    ];

    const minDate = Math.min(...allDates);
    const maxDate = Math.max(...allDates);

    // Compute min and max dates for the selected year
    const filteredDates = selectedYear
        ? [
            ...filteredChartData.map(data => data.testDate),
            ...filteredGrindHistory.map(data => new Date(data.grindDate).getTime())
        ]
        : allDates;

    const yearMinDate = Math.min(...filteredDates);
    const yearMaxDate = Math.max(...filteredDates);

    // Component JSX
    return (
        <div className="px-2 animate-fade-down animate-duration-300">
            <div className='grid grid-cols-2 gap-2 mb-4'>
                <div>
                    <label className='text-sm' htmlFor="brand">{t('brand')}</label>
                    <p className='font-semibold'>{ski.brand ? ski.brand : "--"}</p>
                </div>

                <div>
                    <label className='text-sm' htmlFor="model">{t('model')}</label>
                    <p className='font-semibold'>{ski.model ? ski.model : "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="length">{t('length')}</label>
                    <p className='font-semibold'>{ski.length ? ski.length : "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="stiffness">{t('stiffness')}</label>
                    <p className='font-semibold'>{ski.stiffness ? ski.stiffness : "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="base">Base</label>
                    <p className='font-semibold'>{ski.base ? ski.base : "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="construction">{t('construction')}</label>
                    <p className='font-semibold'>{ski.construction ? ski.construction : "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="grind_date">{t('grind_date')}</label>
                    <p className='font-semibold'>{ski.grindDate ? new Date((ski.grindDate)).toLocaleDateString() : "--"}</p>
                </div>
                <div>
                    <label className='text-sm' htmlFor="comment">{t('comment')}</label>
                    <p className='font-semibold'>{ski.comment ? ski.comment : "--"}</p>
                </div>
            </div>

            {ski.testIds && (
                <div>
                    <h2 className='text-xl border-t py-2 my-2 font-semibold'>{t('performance')}</h2>
                    <div className='mb-4'>
                        <label htmlFor="year-select">{t('select_year')}:</label>
                        <select id="year-select" value={selectedYear} onChange={handleYearChange} className='bg-sbtn rounded mx-2 p-1 px-2 text-text border cursor-pointer'>
                            <option value="">{t('all')}</option>
                            {[...new Set(chartData.map(data => new Date(data.testDate).getFullYear().toString()))].map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>

                    {!chartData.length ? (
                        <span>{t('test_to_see_performace')}</span>
                    ) : (
                        <div className='overflow-auto scroll-smooth rounded-xl' ref={chartContainerRef} >
                            <ResponsiveContainer width={containerWidth} height={250} className={'animate-fade'}>
                                <LineChart
                                    data={filteredChartData}
                                    margin={{ top: 50, right: 50, left: 20, bottom: 20 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="testDate"
                                        type="number"
                                        domain={[selectedYear ? yearMinDate : minDate, selectedYear ? yearMaxDate : maxDate]} // Update XAxis domain based on selected year or all data
                                        tickFormatter={(tick) => new Date(tick).toLocaleDateString()}
                                        ticks={[...new Set([...filteredChartData.map(data => data.testDate), ...filteredGrindHistory.map(data => new Date(data.grindDate).getTime())])].sort((a, b) => a - b)} // Combine and sort test and grind dates
                                        label={{ value: 'Test date', position: 'insideBottom', offset: -30, fontSize: 18 }}
                                        stroke='var(--color-text)'
                                        tickMargin={10}
                                    />

                                    <YAxis
                                        type="number"
                                        domain={['dataMax', 'dataMin']}
                                        allowDecimals={false}
                                        label={{ value: 'Rank', angle: -90, position: 'insideLeft', fontSize: 18 }}
                                        stroke='var(--color-text)'
                                    />
                                    <Tooltip content={CustomTooltip} />
                                    <Legend align="left" verticalAlign="bottom" />
                                    <Line type="monotone" dataKey="place" stroke="var(--color-btn)" strokeWidth={2} activeDot={{ r: 8 }} />
                                    {filteredGrindHistory.map((data, index) => (
                                        <ReferenceLine
                                            key={`grind-${index}`}
                                            x={new Date(data.grindDate).getTime()}
                                            stroke='var(--color-reference)'
                                            strokeWidth={2}
                                            strokeDasharray="1 0"
                                            label={{
                                                value: `${data.grind}`,
                                                position: 'top', // Position the label on top of the reference line
                                                offset: 15, // Adjust label offset as needed
                                                fontSize: 14,
                                                fill: "var(--color-text)",
                                            }}
                                        />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SkiDetail;
