import React from 'react';
import { useTranslation } from 'react-i18next';

const SkiFilter = ({ onFilterChange, currentFilter }) => {
  const { t } = useTranslation();

  const handleFilterChange = (event) => {
    const { value } = event.target;
    onFilterChange(value);
  };

  return (
    <div className='flex flex-col w-fit'>
      <label htmlFor="filter" className="text-sm font-semibold mb-1">{t('filter')}</label>
      <select
        id="filter"
        value={currentFilter}
        onChange={handleFilterChange}
        className="bg-sbtn text-btn-txt rounded p-2"
      >
        <option value="">{t('all')}</option>
        <option value="skate">{t('skate')}</option>
        <option value="classic">{t('classic')}</option>
        <option value="archived">{t('archived')}</option>
        <option value="all_with_archive">{`${t('all')} + ${t('archived')}`}</option>
      </select>
    </div>
  );
};

export default SkiFilter;
