import atomicLogo from '../assets/atomic_logo.png'
import fischerLogo from '../assets/fischer_logo.png'
import rossignolLogo from '../assets/rossignol_logo.png'
import salomonLogo from '../assets/salomon_logo.png'
import { formatISO, parseISO } from 'date-fns';


export const mapRankingsToTournamentData = (rankings) => ({
    rankings: rankings.map(({ cumulativeScore, skiId }) => ({
        score: cumulativeScore,
        skiId: skiId
    }))
});

export const calculateSkiRankings = (rankings, skis) => {
    const sortedRankings = [...rankings].sort((a, b) => a.cumulativeScore - b.cumulativeScore); // Sort in descending order of score
    let currentRank = 1;
    let lastScore = null;

    return sortedRankings.map((ranking, index) => {
        const ski = skis.find(s => s.serialNumber === ranking.serialNumber);

        // Increment rank only if the score is different from the last one
        if (lastScore !== ranking.cumulativeScore) {
            currentRank = index + 1;
            lastScore = ranking.cumulativeScore;
        }

        return {
            id: ski.id,
            position: `${currentRank}/${sortedRankings.length}`
        };
    });
};

export const getBrandImg = (brand) => {
    const normalizedBrand = brand.toLowerCase();
    switch (normalizedBrand) {
        case 'atomic':
            // Setting fixed width and height, e.g., w-32 h-32 (8rem by 8rem)
            return <img className='rounded w-32 h-32' src={atomicLogo} alt="Atomic Logo" />;
        case 'fischer':
            // Keeping the same dimensions for consistency
            return <img className='rounded w-32 h-32' src={fischerLogo} alt="Fischer Logo" />;
        case 'rossignol':
            // Keeping the same dimensions for consistency
            return <img className='rounded w-32 h-32' src={rossignolLogo} alt="Rossignol Logo" />;
        case 'salomon':
            // Keeping the same dimensions for consistency
            return <img className='rounded w-32 h-32' src={salomonLogo} alt="Salomon Logo" />;
        default:
            return "";
    }
}

export const formatDateForInputWithTime = (timestamp) => {
    if (!timestamp) return '';
    try {
        const date = typeof timestamp === 'string' ? parseISO(timestamp) : new Date(timestamp.seconds ? timestamp.seconds * 1000 : timestamp);
        return formatISO(date, { representation: 'date' }) + 'T' + formatISO(date, { representation: 'time' }).substring(0, 5);
    } catch (error) {
        console.error('Error formatting date for input:', error);
        return '';
    }
};

export const formatDateForInput = (timestamp) => {
    if (!timestamp) return '';
    try {
        const date = typeof timestamp === 'string' ? parseISO(timestamp) : new Date(timestamp.seconds ? timestamp.seconds * 1000 : timestamp);
        return formatISO(date, { representation: 'date' });
    } catch (error) {
        console.error('Error formatting date for input:', error);
        return '';
    }
};


