const ProgressBar = ({ progress }) => {
    if(progress===100){
        progress=0
    }
    return (
        <div className="progress-bar-container w-100 mb-5">
            <div style={{ width: `${progress}%` }} className={`h-0.5 bg-dominant rounded-xl`}
            ></div>
            <div style={{ width: `${100}%` }} className={`h-0.5 bg-slate-100 rounded-xl`}></div>
        </div>
    );
};



export default ProgressBar
