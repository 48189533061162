// src/hooks/useTournamentResults.js
import { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, limit, addDoc } from 'firebase/firestore';
import { db } from '../firebase/config'; 
import { useAuth } from '../context/AuthContext';
import { updateTournamentResult, deleteTournamentAndRelatedRankings } from '../firebase/firestoreFunctions';


const useTournamentResults = () => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        const fetchResults = async () => {
            if (!user) return;
            setLoading(true);
            try {
                const resultsCollectionRef = collection(db, `users/${user.uid}/testResults`);
                const q = query(resultsCollectionRef, orderBy("timestamp", "desc"), limit(30));
                const querySnapshot = await getDocs(q);
                let fetchedResults = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    // Sort rankings by score before setting the state
                    if (data.rankings && data.rankings.length) {
                        data.rankings.sort((a, b) => a.score - b.score);
                    }
                    return { id: doc.id, ...data };
                });
                setResults(fetchedResults);
            } catch (error) {
                console.error('Error fetching tournament results:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchResults();
    }, [user]);
    

    const addTournamentResult = async (tournamentData) => {
        if (!user) {
            console.error('User not authenticated');
            return;
        }

        try {
            const docRef = await addDoc(collection(db, `users/${user.uid}/testResults`), {
                ...tournamentData,
                timestamp: new Date()
            });
            console.log("Tournament result added with ID: ", docRef.id);
            setResults([...results, { ...tournamentData, id: docRef.id }]);
        } catch (error) {
            console.error("Error adding tournament result: ", error);
            setError(error);
        }
    };

    const deleteResult = async (userId, resultId) => {
        setLoading(true);
        try {
            await deleteTournamentAndRelatedRankings(userId, resultId);
    
            // Filter out the deleted result from the state directly within the hook
            const updatedResults = results.filter(result => result.id !== resultId);
            setResults(updatedResults);  // Update the state to reflect the deletion immediately
        } catch (error) {
            console.error("Error deleting tournament result:", error);
        }finally{
            setLoading(false);
        }
    };

    const updateResult = async (resultId, updatedData) => {
        await updateTournamentResult(user.uid, resultId, updatedData);
        const updatedResults = results.map(result => {
            if (result.id === resultId) {
                // Assume updatedData contains the updated rankings
                if (updatedData.rankings && updatedData.rankings.length) {
                    updatedData.rankings.sort((a, b) => a.score - b.score);
                }
                return { ...result, ...updatedData };
            }
            return result;
        });
        setResults(updatedResults);
    };

    return { results, loading, error, addTournamentResult, deleteResult, updateResult };

};

export default useTournamentResults;
